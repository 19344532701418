<template>
  <div>
    <p v-if="isLoading">Loading...</p>
    <section
      class="input-unit"
      :class="{
        'hide-border': hideBorder,
        'unset-max-width': unsetMaxWidth,
        'bold': bold,
        'autocomplete-upah': !upahName && !readOnly
      }"
      v-if="!isLoading"
    >
      <b-field>
        <b-autocomplete
          field="name"
          placeholder="Pilih Upah Kerja"
          :clearable="!disabled"
          open-on-focus
          v-model="upahName"
          :data="filteredData"
          :disabled="disabled"
          @focus="resetSearch"
          @select="(option) => handleSelectUnit(option)"
          @input="(e) => handleChangeInput(e)"
        ></b-autocomplete>
      </b-field>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'InputAutocompleteUpah',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Object, String],
      default: null
    },
    hideBorder: {
      type: Boolean,
      default: false
    },
    bold: {
      type: Boolean,
      default: false
    },
    unsetMaxWidth: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    idAutocomplete: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      upahName: '',
      search: '',
      dataUnit: []
    }
  },
  computed: {
    ...mapGetters({
      detailUnitPrice: 'unitPrice/getDetailUnitPrice'
    }),
    filteredData () {
      return this.dataUnit.filter((option) => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.search ? this.search.toLowerCase() : '') >= 0
        )
      })
    }
  },
  methods: {
    handleChangeInput (e) {
      this.search = e
    },
    handleSelectUnit (option) {
      this.$emit('selected', option || 'empty')
    },
    resetSearch () {
      this.search = ''
    },
    getDataUnit () {
      this.dataUnit = [
        {
          fee_type: 1,
          name: 'Upah Tukang'
        },
        {
          fee_type: 2,
          name: 'Upah Kenek'
        }
      ]
    }
  },
  mounted () {
    if (this.value && this.value !== 'empty') {
      this.upahName = this.value.name
      const option = {
        fee_type: this.value.fee_type,
        name: this.value.name
      }
      this.handleSelectUnit(option)
    }
  },
  created () {
    this.getDataUnit()
  },
  watch: {
    detailUnitPrice: {
      handler (val) {
        if (this.idAutocomplete === `${val.index}-${val.code.replace(/^edit_/, '')}`) {
          this.upahName = val.name || ''
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/_variables.scss';

.input-unit input {
  border-radius: 10px !important;
  border: 1px solid $color-grey-2 !important;
  font-size: 15px;
}

.input-unit.hide-border input {
  border: none !important;
}
</style>

<style>
.autocomplete-upah .autocomplete input::placeholder {
  color: #E10009;
  opacity: 1;
  font-family: 'Averta-Italic' !important;
}

.autocomplete-upah .autocomplete input::-ms-input-placeholder {
  color: #E10009;
  font-family: 'Averta-Italic' !important;
}

.input-unit.unset-max-width .autocomplete .dropdown-menu {
  max-width: unset !important;
}

.input-unit .autocomplete .dropdown-menu {
  z-index: 22;
}

.input-unit.hide-border .autocomplete input {
  background: white !important;
}

.input-unit.bold .autocomplete input {
  font-family: 'Averta-Bold';
  color: #474747;
  font-size: 14px;
}
</style>
